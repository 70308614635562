import React from "react";

const Skills = () => {
	return (
		<div>

		</div>
	);
};

export default Skills;
